.login_box {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* width: 300px; */
}

.login_box > input {
	margin: 10px;
	width: 200px;
}

.login_box > button {
	margin: 10px;
	width: 100px;
}